import chroma from "chroma-js";
import styled from "styled-components";


export const Textarea = styled.textarea`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    font-size: 1.2rem;
    background-color: transparent;
    color: ${props => chroma(props.theme.left.primary).set("hsl.l", chroma(props.theme.left.primary).get("hsl.l") - .3).hex()};
`