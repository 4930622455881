import React from "react";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { ThemeProvider } from "styled-components";
import './App.css';
import JsonEditor from "./components/JsonEditor";
import Selection from "./components/Selection";
import { Bar } from "./components/styled/Bar";
import { Input } from "./components/styled/Input";
import { LeftPanel, Panel, PanelParent, RightPanel } from "./components/styled/Panel";
import { Button } from "./components/styled/SendButton";
import { StatusContainer, StatusText } from "./components/styled/Status";
import { Textarea } from "./components/styled/Textarea";
import { fetchWithBody } from "./requests/Body";
import { fetchWithoutBody } from "./requests/WithoutBody";
import { SunFill, MoonStarsFill } from "@styled-icons/bootstrap"
import chroma from "chroma-js";

const darkTheme = {
    left: {
        primary: '#ffffff',
        secondary: '#282c34',
    },
    right: {
        primary: '#DEE4E7',
        secondary: '#212121',
    },
    tab: {
        secondary: '#ffffff21',
        border: '#aaa',
    }
}

const lightTheme = {
    left: {
        primary: darkTheme.left.secondary,
        secondary: darkTheme.left.primary,
    },
    right: {
        primary: darkTheme.right.secondary,
        secondary: darkTheme.right.primary,
    },
    tab: {
        secondary: '#00000021',
        border: '#aaa',
    }
}

function App() {
    const url = React.useRef<HTMLInputElement>(null);
    const output = React.useRef<HTMLTextAreaElement>(null);

    const [theme, setTheme] = React.useState(darkTheme);
    const [method, setMethod] = React.useState('GET');

    const [response, setResponse] = React.useState({});
    const [content, setContent] = React.useState('');
    const [error, setError] = React.useState('');

    const [header, setHeader] = React.useState(
        JSON.stringify({
            'Content-Type': 'application/json'
        }, null, 2)
    );
    const [body, setBody] = React.useState(
        JSON.stringify({}, null, 2)
    );

    const ThemeSwitcher = () => {
        if (theme === darkTheme)
            return <SunFill size={24} color={theme.right.primary} onClick={() => switchTheme()} />
        return <MoonStarsFill size={24} color={theme.right.primary} onClick={() => switchTheme()} />
    }

    function switchTheme() {
        setTheme(theme === darkTheme ? lightTheme : darkTheme);
    }

    function hasResponse() {
        return Object.keys(response).length > 0;
    }

    function sendRequest() {
        if (!url.current || !output.current)
            return

        setResponse({});
        setContent('');
        setError('');

        output.current.value = 'Sending request...';
        output.current.value += '\n\n'
        output.current.value += '# Header'
        output.current.value += '\n'
        output.current.value += header;
        output.current.value += '\n\n'

        if (method !== 'GET') {
            output.current.value += '# Body'
            output.current.value += '\n'
            output.current.value += body;
            output.current.value += '\n\n'
        }

        output.current.value += '# Response'
        output.current.value += '\n'


        if (method === 'GET') {
            fetchWithoutBody(url.current.value, method, JSON.parse(header), rep => setResponse(rep))
                .then(res => setContent(res))
                .catch(err => setError(err));

            return;
        }

        fetchWithBody(url.current.value, method, JSON.parse(header), body, rep => setResponse(rep))
            .then(res => setContent(res))
            .catch(err => setError(err));
    }

    React.useEffect(() => {
        if (!output.current) return;

        if (!content) {
            output.current.value += error;
            return;
        }

        try {
            output.current.value += JSON.stringify(JSON.parse(content), null, 2);
        } catch (e) {
            output.current.value += e;
        }
    }, [response, error, content]);

    var tabStyle = {
        "--tab-background": theme.tab.secondary,
        "--tab-border": theme.tab.border
    } as React.CSSProperties;

    return (
        <ThemeProvider theme={theme}>
            <PanelParent>
                <LeftPanel>

                    <Bar>
                        <Selection
                            options={[
                                { value: 'GET', label: 'GET', color: '#32a852' },
                                { value: 'POST', label: 'POST', color: '#f1b65f' },
                                { value: 'PUT', label: 'PUT', color: '#2395e7' },
                                { value: 'PATCH', label: 'PATCH', color: '#3ad7f0' },
                                { value: 'DELETE', label: 'DEL', color: '#f03a4c' },
                            ]}
                            onChange={(event) => setMethod(event.value)}
                            width={'120px'}
                        />

                        <Input type="text" placeholder="http://localhost:8080/" ref={url} />
                    </Bar>

                    {/* <Rule /> */}

                    <Tabs style={tabStyle}>
                        <TabList>
                            <Tab>Header</Tab>
                            <Tab>Body</Tab>
                        </TabList>

                        <TabPanel>
                            <Panel>
                                <JsonEditor
                                    onChange={(value) => setHeader(value)}
                                    height={'80vh'}
                                    placeholder={header}
                                />
                            </Panel>
                        </TabPanel>
                        <TabPanel>
                            <Panel>
                                <JsonEditor
                                    onChange={(value) => setBody(value)}
                                    height={'80vh'}
                                    placeholder={body}
                                />
                            </Panel>
                        </TabPanel>
                    </Tabs>

                    <Button onClick={() => sendRequest()}> Send </Button>
                </LeftPanel>
                <RightPanel>
                    {/* <button onClick={() => switchTheme()}>Click me</button> */}

                    <StatusContainer>
                        <StatusText style={{
                            color: hasResponse() ? (response as any).status < 300 ? '#32a852' : '#f03a4c' : '#aaa',
                        }}>
                            {hasResponse() ? (response as any).status + " " + (response as any).statusText : ""}
                        </StatusText>
                        <StatusText style={{
                            color: hasResponse() ? (response as any).time < 100 ? '#32a852' : '#f03a4c' : '#aaa',
                        }}>
                            {hasResponse() ? (response as any).time + "ms" : ""}
                        </StatusText>

                    </StatusContainer>

                    <Textarea ref={output} style={{
                        marginTop: '38px',
                        height: '90vh',
                        width: '90%',
                    }} spellCheck={false} readOnly />

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.left.secondary,
                        border: '1px solid ' + chroma(theme.left.primary).set("hsl.l", chroma(theme.left.primary).get("hsl.l") - .7).hex(),
                        position: 'fixed',
                        borderRadius: '50%',
                        bottom: '10px',
                        right: '10px',
                        height: '40px',
                        width: '40px',
                    }}>
                        <ThemeSwitcher />
                    </div>

                </RightPanel>
            </PanelParent>
        </ThemeProvider>
    );
}

export default App;
