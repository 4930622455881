import styled from "styled-components";

export const Button = styled.button`
    background-image: linear-gradient(to right, #1D976C 0%, #93F9B9  51%, #1D976C  100%);
    background-size: 200% auto;
    box-shadow: -5px 5px 20px #93F9B9;
    transition: 0.5s;

    position: fixed;
    width: 50%;
    height: 40px;
    color: white;
    left: 0;
    bottom: 0;

    text-transform: uppercase;
    text-align: center;
    border: none;

    &:hover {
       background-position: right center;
       text-decoration: none;
    }
`