import styled from "styled-components";

export const Input = styled.input`
    background-color: #ffffff00;

    border: none;
    color: ${props => props.theme.left.primary};

    height: 100%;
    width: 100%;

    font-size: 1.0825em;

    :focus{
        outline: none;
    }
`