import chroma from "chroma-js";
import { StylesConfig } from "react-select";
import Select from 'react-select';

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    color: color,
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: '50%',
        content: '""',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const customStyles: StylesConfig<any, any, any> = {
    control: styles => ({ ...styles, backgroundColor: '#ffffff00', border: 'none', borderRadius: 0, boxShadow: 'none' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'black') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor:
                    !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
        };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
}

interface Props {
    options: { value: string, label: string, color: string }[];
    onChange?: (value: { value: string, label: string, color: string }) => void;
    width?: string;
}

function Selection(props: Props) {
    return (
        <div style={{
            minWidth: props.width || '100%',
        }}>
            <Select
                options={props.options}
                defaultValue={props.options[0]}
                onChange={(value) => { props.onChange && props.onChange(value) }}
                styles={customStyles}
            />
        </div>
    )
}

export default Selection;