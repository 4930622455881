import styled from "styled-components";

export const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    background: ${props => props.theme.left.secondary};
    border-bottom: 1px solid ${props => props.theme.tab.border};
    position: fixed;
    top: 0;
    width: 100%;
    height: 38px;
`

export const StatusText = styled.p`
    top: 0;
    padding: 8px;
    margin: 0;
`