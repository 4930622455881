import styled from "styled-components";

export const PanelParent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const LeftPanel = styled.div`
    background-color: ${props => props.theme.left.secondary};
    color: ${props => props.theme.left.primary};

    min-height: 100vh;
    max-height: 100vh;
    width: 50%;
    top: 0;

    overflow-y: scroll;
`

export const RightPanel = styled.div`
    background-color: ${props => props.theme.right.secondary};
    color: ${props => props.theme.right.primary};

    min-height: 100vh;
    max-height: 100vh;
    width: 50%;
    top: 0;

    overflow-y: scroll;
`

export const Panel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
`