import React from "react";
import { Textarea } from "./styled/Textarea";

interface Props {
    width?: string;
    height?: string;
    placeholder?: string;
    onChange?: (json: string) => void;
}

function JsonEditor(props: Props) {

    const [value, setValue] = React.useState(props.placeholder || "");
    const [error, setError] = React.useState(false);

    const textarea = React.useRef<HTMLTextAreaElement>(null);

    function update(event: any) {
        try {
            var obj = JSON.parse(event.target.value);
            var pretty = JSON.stringify(obj, null, 2);

            event.target.value = pretty;

            props.onChange && props.onChange(pretty);
            setValue(pretty);
            setError(false);
        } catch (e) {
            setError(true);
        }
    }

    return (
        <Textarea ref={textarea} style={{
            width: props.width ? props.width : "100%",
            height: props.height ? props.height : "100%",
            textDecoration: error ? "underline red" : "none",
        }} onChange={event => update(event)} spellCheck={false}>
            {value}
        </Textarea>
    )
}

export default JsonEditor;