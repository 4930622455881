
interface Response {
    status: number;
    statusText: string;
    type: string;
    url: string;
    redirected: boolean;
    time: number;
    ok: boolean;
}

export function fetchWithBody(url: string, method: string = 'POST', headers: any = {}, body: string, response: (response: Response) => void) {

    var millis = new Date().getTime();

    return fetch(url, {
        method: method,
        headers: headers,
        body: body,
    }).then(rep => {
        response({
            status: rep.status,
            statusText: rep.statusText,
            type: rep.type,
            url: rep.url,
            redirected: rep.redirected,
            time: new Date().getTime() - millis,
            ok: rep.ok,
        } as Response);

        return rep.text();
    });
}